<script>
    import { layoutOption, apiConfig, mode, buInfo } from '../store/inapp-store'
    import { opOpenScanner, opQueryCameraStatus, hideNavbar } from '../store/openpoint'
    import { checkQRCode } from '../store/business-unit'
    import { Modal, Loader } from '../components'
    import { onMount } from 'svelte'

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    const count = parseInt(urlParams.get('c'))

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl = `/return-via-scanner`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let allowCamera = false
    let scannerType = 'QRCode'
    let regexContent = ['.*']
    let pincode = ''
    let timer
    let scanCupCount = 0
    let maxCount = count

    let isOpen = false
    let modal = {
        title : '掃描QRCODE',
        message : '',
        type: 'alert'
    }

    $:console.log('allowCamera', allowCamera)
    onMount(() =>{
        try {
            if($mode === 'dev') { mockEnableCamera() }
            timer = setInterval(checkCameraStatus, 100)
            opQueryCameraStatus();
        } catch (e) {
            console.log(e.msg)
        }
    })

    /**
     * 檢核是否具有開啟相機權限
     */
    function checkCameraStatus() {
        var value = document.getElementById('isCamera').value;
        if (value === '') { return false }

        allowCamera = value === 'allow'
        if(allowCamera === false) {
            showDialog('請先開啟相機權限')
        }

        clearInterval(timer)
    }

    /**
     * 開啟公版掃描器
     **/
    function openScanner() {
        if(allowCamera == false) { return false }

        try {
            clearInterval(timer)

            if($mode === 'dev') { 
                mockScanResult() 
            } else {
                opOpenScanner(scannerType, regexContent);
            }

            timer = setInterval(checkScanResult, 500)

        } catch (e) {
            console.log(e.msg)
        }
    }

    /**
     * 檢核二維碼掃描結果
     */
    function checkScanResult() {
        let scanStatus = document.getElementById('statusCode').value 
        let scanContentList =  document.getElementById('scanContentList').value 
        // console.log('scanStatus=', scanStatus)
        // console.log('scanContentList=', scanContentList)
        if(scanStatus === '00' && scanContentList !== '') {
            let scanResult = {}
            scanResult = JSON.parse(scanContentList)
            pincode = scanResult.scan_content_list[0].scan_content
            clearInterval(timer)
            
            // 檢核杯身條碼
            var isValid = checkQRCode(pincode)
            if(!isValid) {
                showDialog('QRCODE不正確，請重新掃描')
            }

            // 累計歸還杯數
            if(isValid && scanCupCount < count) { scanCupCount ++ }
        }

        // 關閉天區(iOS)
        hideNavbar()
    }

    function redirectScanPincode() {
        location.href = `/return-scan-pincode?id=${id}&c=${scanCupCount}`
    }

    function showDialog(msg) {
        modal.message  = msg
        isOpen = true
    }

    function onCloseModal() {
        if(allowCamera) { 
            isOpen = false 
            return false
        }

        location.href = '/return-via-scanner' 
    }

    function mockEnableCamera() {
        document.getElementById('isCamera').value = 'allow';
    }

    function mockScanResult() {
        var mockPincode = 'https://www.citycafe.com.tw/file/ingredient.pdf#AB020H00000000'
        document.getElementById('statusCode').value = '00'
        document.getElementById('scanContentList').value = '{"scan_content_list":[{"scan_order":"1","scan_content":"' + mockPincode + '","barcode_type":"7"}]}'
    }
</script>

<div class="content-wrap">
    <div class="choose">
        <p class="chs-p">掃描歸還杯身QRCODE</p>
    </div>

    <div class="scan-box" on:click={()=> openScanner()}>
        <div class="takecup-scan">
            <img src="img/ic-scan-card.svg" alt="">
            <p>
                開啟相機掃描杯身上的<span>QRCODE</span>
            </p>
        </div>
    </div>

    <div class="px-3P LR-0">
        <h5 class="font-weight-bolder LH-15">
            應歸還杯數：<span style="color: red;">{count}</span> 杯
            <br>
            已掃描歸還杯子數：<span style="color: red;">{scanCupCount}</span> 杯
            <br>
            請掃描租借循環杯上的 QRCODE
        </h5>
    </div>

    <div class="takecup-iq-sm">
        <p>
            操作說明：
            <br>
            請掃描欲還杯的杯身QRCODE進行還杯(可進行部分歸還)，掃描完畢請按下確認歸還後，並再掃描門市櫃台上的還杯QRCODE，即完成還杯步驟。
        </p>
    </div>
    <div class="takecup-img-box">
        <img src="img/img-cup-scan.jpg" alt="" class="w-100">
    </div>

    <input type="hidden" class="w-100" id="isCamera">
    <input type="hidden" class="w-100" id="statusCode">
    <input type="hidden" class="w-100" id="scanContentList">
    <input type="hidden" class="w-100" bind:value={pincode}>
</div>

{#if scanCupCount === 0}
<div class="il-fx-btm" on:click={()=> openScanner()}>
    <button class="il-send submit-a {allowCamera ? 'active' : '' }">確認掃描</button>
</div>
{:else if (scanCupCount == maxCount)}
<div class="il-fx-btm" on:click={()=> redirectScanPincode()}>
    <button class="il-send submit-a active">確認歸還</button>
</div>
{:else}
<div class="pay-d-fx-btm">
    <div class="pay-d-send submit-a" on:click={()=> openScanner()}>
        <p class="pay-d-send-p">繼續掃描</p>
    </div>
    <div class="pay-d-send submit-a" on:click={()=> redirectScanPincode()}>
        <p class="pay-d-send-ps">確認歸還</p>
    </div>
</div>
{/if}

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
