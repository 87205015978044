<script>
    import { layoutOption, apiConfig, mode, buInfo } from '../store/inapp-store'
    import { opOpenScanner, opQueryCameraStatus, hideNavbar } from '../store/openpoint'
    import { Modal } from '../components'
    import { onMount } from 'svelte'

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    const count = parseInt(urlParams.get('c'))

    $layoutOption.navTitle = '還杯'
    $layoutOption.navBackUrl = `/return-via-scanner`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let allowCamera = false
    let scannerType = 'QRCode'
    let regexContent = ['.*']
    let pincode = ''
    let timer

    let isOpen = false
    let modal = {
        title : '掃描QRCODE',
        message : '',
        type: 'alert'
    }

    $:console.log('allowCamera', allowCamera)
    onMount(() =>{
        try {
            if($mode === 'dev') { mockEnableCamera() }
            timer = setInterval(checkCameraStatus, 100)
            opQueryCameraStatus();
        } catch (e) {
            console.log(e.msg)
        }
    })

    /**
     * 檢核是否具有開啟相機權限
     */
    function checkCameraStatus() {
        var value = document.getElementById('isCamera').value;
        if (value === '') { return false }

        allowCamera = value === 'allow'
        if(allowCamera === false) {
            showDialog('請先開啟相機權限')
        }

        clearInterval(timer)
    }

    /**
     * 開啟公版掃描器
     **/
    function openScanner() {
        if(allowCamera == false) { return false }

        try {
             clearInterval(timer)

            if($mode === 'dev') { 
                mockScanResult() 
            } else {
                opOpenScanner(scannerType, regexContent);
            }

            timer = setInterval(checkScanResult, 500)

        } catch (e) {
            console.log(e.msg)
        }
    }

    /**
     * 檢核二維碼掃描結果
     */
    function checkScanResult() {
        let scanStatus = document.getElementById('statusCode').value 
        let scanContentList =  document.getElementById('scanContentList').value 
        // console.log('scanStatus=', scanStatus)
        // console.log('scanContentList=', scanContentList)
        if(scanStatus === '00' && scanContentList !== '') {
            let scanResult = {}
            scanResult = JSON.parse(scanContentList)
            pincode = scanResult.scan_content_list[0].scan_content
            clearInterval(timer)
            
            // 檢核pincode(key)
            fetch(`${$apiConfig.apiUrl}/bu/check?code=${$apiConfig.apiKey}&key=${pincode}&mode=RETURN`, {
                    method: 'GET'
                })
                .then(response => response.json())
                .then(d => {
                    console.log(d)
                    if(d.returnCode === '0000') {
                        $buInfo = { pincode: pincode , store: d.info }
                        location.href = `/return-scan-success?id=${id}&c=${count}`
                    } else if(d.returnCode === '3005') {
                        showDialog('該回收門市不存在，請與門市人員確認(門市已下架、門市已逾期)')
                    } else {
                        showDialog('QRCODE不正確，請重新掃描')
                    }

                    // 清除掃描結果，以免反覆出現檢核失敗訊息
                    document.getElementById('scanContentList').value = ''
                });
        }

        // 關閉天區(iOS)
        hideNavbar()
    }

    function showDialog(msg) {
        modal.message  = msg
        isOpen = true
    }

    function onCloseModal() {
        if(allowCamera) { 
            isOpen = false 
            return false
        }

        location.href = '/redeem-via-scanner' 
    }

    function mockEnableCamera() {
        document.getElementById('isCamera').value = 'allow';
    }

    function mockScanResult() {
        var mockPincode = '9b242e73d0da46e4b0447595f719205a'
        document.getElementById('statusCode').value = '00'
        document.getElementById('scanContentList').value = '{"scan_content_list":[{"scan_order":"1","scan_content":"' + mockPincode + '","barcode_type":"7"}]}'
    }
</script>

<div class="content-wrap">
    <div class="choose">
        <p class="chs-p">輸入還杯驗證碼</p>
    </div>

    <div class="scan-box" on:click={()=> openScanner()}>
        <div class="takecup-scan">
            <img src="img/ic-scan-card.svg" alt="ic-scan-card">
            <p>
                開啟相機掃描桌上立牌
                <br>
                <span>
                    [還杯驗證]的 QRCODE
                </span>
            </p>
        </div>
    </div>

    <div class="px-3P LR-0">
        <h4 class="font-weight-bolder LH-15">
            請門市回收 <span style="color: red;">{count}</span> 個循環杯
        </h4>
    </div>

    <div class="takecup-iq-sm">
        <p>
            操作說明：
            <br>
            請門市人員確實並確認回收循環杯與數量後，並提供還杯確認驗證碼QRCODE給消費者，才能完成歸還作業。
        </p>
    </div>

    <div class="takecup-img-box">
        <img src="img/img-return-scan.jpg" alt="img-return-scan" class="w-100">
    </div>

    <input type="hidden" class="w-100" id="isCamera">
    <input type="hidden" class="w-100" id="statusCode">
    <input type="hidden" class="w-100" id="scanContentList">
    <input type="hidden" class="w-100" bind:value={pincode}>
</div>

<div class="il-fx-btm" on:click={()=> openScanner()}>
    <button class="il-send submit-a {allowCamera ? 'active' : '' }">確認掃描</button>
</div>

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
