<script>
    import { layoutOption, apiConfig, mode, buInfo } from '../store/inapp-store'
    import { opOpenScanner, opQueryCameraStatus, hideNavbar } from '../store/openpoint'
    import { checkQRCode } from '../store/business-unit'
    import { Modal, Loader } from '../components'
    import { onMount } from 'svelte'

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    const count = parseInt(urlParams.get('c'))
    const size = urlParams.get('s')

    $layoutOption.navTitle = '兌換取杯'
    $layoutOption.navBackUrl = `/redeem-scan-pincode?id=${id}&c=${count}`
    $layoutOption.showCarousel = false
    $layoutOption.showCancel = false
    $layoutOption.showHome = true

    let allowCamera = false
    let scannerType = 'QRCode'
    let regexContent = ['.*']
    let pincode = ''
    let timer
    let scanCupCount = 0
    let cupSize = size === 'M' ? '中杯' : size === 'L' ? '大杯' : size === 'XL' ? '特大杯' : ''

    let isOpen = false
    let modal = {
        title : '掃描QRCODE',
        message : '',
        type: 'alert'
    }

    $:console.log('allowCamera', allowCamera)
    onMount(() =>{
        try {
            if($mode === 'dev') { mockEnableCamera() }
            timer = setInterval(checkCameraStatus, 100)
            opQueryCameraStatus();
        } catch (e) {
            console.log(e.msg)
        }
    })

    /**
     * 檢核是否具有開啟相機權限
     */
    function checkCameraStatus() {
        var value = document.getElementById('isCamera').value;
        if (value === '') { return false }

        allowCamera = value === 'allow'
        if(allowCamera === false) {
            showDialog('請先開啟相機權限')
        }

        clearInterval(timer)
    }

    /**
     * 開啟公版掃描器
     **/
    function openScanner() {
        if(allowCamera == false) { return false }

        try {
            clearInterval(timer)

            if($mode === 'dev') { 
                mockScanResult() 
            } else {
                opOpenScanner(scannerType, regexContent);
            }

            timer = setInterval(checkScanResult, 500)

        } catch (e) {
            console.log(e.msg)
        }
    }

    /**
     * 檢核二維碼掃描結果
     */
    function checkScanResult() {
        let scanStatus = document.getElementById('statusCode').value 
        let scanContentList =  document.getElementById('scanContentList').value 
        // console.log('scanStatus=', scanStatus)
        // console.log('scanContentList=', scanContentList)

        if(scanStatus === '00' && scanContentList !== '') {
            let scanResult = {}
            scanResult = JSON.parse(scanContentList)
            pincode = scanResult.scan_content_list[0].scan_content
            clearInterval(timer)
            
             // 檢核杯身條碼
             var isValid = checkQRCode(pincode)
            if(!isValid) {
                showDialog('QRCODE不正確，請重新掃描')
            }

            if(isValid && scanCupCount < count) { scanCupCount ++ }

            // 掃描杯數達到訂單上限，就進行銷案
            if(scanCupCount === count) {
                location.href = `/redeem-scan-success?id=${id}&c=${count}`
            }
        }

        // 關閉天區(iOS)
        hideNavbar()
    }

    function showDialog(msg) {
        modal.message  = msg
        isOpen = true
    }

    function onCloseModal() {
        if(allowCamera) { 
            isOpen = false 
            return false
        }

        location.href = '/redeem-via-scanner' 
    }

    function mockEnableCamera() {
        document.getElementById('isCamera').value = 'allow';
    }

    function mockScanResult() {
        var mockPincode = 'https://www.citycafe.com.tw/file/ingredient.pdf#AB020H00000000'
        document.getElementById('statusCode').value = '00'
        document.getElementById('scanContentList').value = '{"scan_content_list":[{"scan_order":"1","scan_content":"' + mockPincode + '","barcode_type":"7"}]}'
    }
</script>
<style>
    .takecup-DIR{
        font-weight: 500;
    }
</style>
<div class="content-wrap">
    <div class="choose">
        <p class="chs-p">掃描租借杯身QRCODE</p>
    </div>

    <div class="scan-box" on:click={()=> openScanner()}>
        <div class="takecup-scan">
            <img src="img/ic-scan-card.svg" alt="">
            <p>
                開啟相機掃描杯身上的<span>QRCODE</span>
            </p>
        </div>
    </div>

    <div class="takecup-DIR">
        <span>
            您位於{$buInfo.store.storeName}門市
            <br>
            請先確認門市是否有足夠杯子
        </span>
    </div>

    <div class="px-3P LR-0">
        <h5 class="font-weight-bolder LH-15">
            租借杯數：<span style="color: red;">{count}</span> 杯 <span style="color: red;"> ({cupSize}循環杯)</span>
            <br>
            已掃描杯數：<span style="color: red;">{scanCupCount}</span> 杯
            <br>
            請掃描租借循環杯上的QRCODE
        </h5>
    </div>

    <div class="takecup-iq-sm">
        <p>
            操作說明：
            <br>
            若門市杯數不足，請至其他門市租借，或重新登記借杯。原借杯訂單可做取消或是超過三天未兌換取杯，系統會主動取消訂單並退款。
        </p>
    </div>
    <div class="takecup-img-box">
        <img src="img/img-cup-scan.jpg" alt="" class="w-100">
    </div>

    <input type="hidden" class="w-100" id="isCamera">
    <input type="hidden" class="w-100" id="statusCode">
    <input type="hidden" class="w-100" id="scanContentList">
    <input type="hidden" class="w-100" bind:value={pincode}>
</div>

<Modal {modal} {isOpen} on:closeModal={onCloseModal}></Modal>
